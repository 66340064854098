<template>
    <div class="login">
        <h5header :name="$t('sign.desc1')" :right="true" />
        <div class="login-con flex">
            <div class="login-left">
                <div class="left-name">{{ $t('sign.desc3') }}</div>
                <div class="left-title">{{ $t('sign.desc4') }}</div>
                <img src="../../public/images/new/loginimg.png" alt="" />
            </div>

            <!-- v-if="showWallet && stepIndex == 1" @click="showWallet = false" -->
            <div class="login-right" :class="showWallet ? 'login-top' : ''">
                <div class="back-btn flex" v-if="showWallet && stepIndex == 1" @click="showWallet = false">
                    <img src="../../public/images/new/back-ico.png" alt="" />上一步
                </div>
                <div class="login-right-box" v-if="!showWallet">
                    <div class="right-name">{{ $t('sign.desc5') }}</div>
                    <div class="right-title">{{ $t('sign.desc6') }}</div>
                    <div class="right-input flex">
                        <input type="text" :placeholder="$t('sign.desc7')" v-model="account"
                            onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" />
                        <img src="../../public/images/new/x.png" alt="" v-if="account != ''" @click="handlerClear" />
                    </div>
                    <div class="right-forgot flex">
                        <div></div>
                        <p @click="$router.push('/forgotname')">{{ $t('sign.desc8') }}</p>
                    </div>
                    <div class="right-title">{{ $t('sign.desc9') }}</div>
                    <div class="right-input flex">
                        <input :type="psdtype" :placeholder="$t('sign.desc10')" v-model="password"
                            onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" />
                        <img :src="psdtype == 'password' ? require('../../public/images/ceyes.png') : require('../../public/images/eyesopen.png')"
                            alt="" @click="handlerEye" />
                    </div>
                    <div class="right-forgot flex">
                        <div class="flex">
                            <img :src="check ? require('../../public/images/new/checkimg.png') : require('../../public/images/new/checkimg1.png')"
                                alt="" @click="handlerCheck" />{{ $t('sign.desc11') }}
                        </div>
                        <p @click="$router.push('/forgot')">{{ $t('sign.desc12') }}</p>
                    </div>
                    <div class="login-btn flexcenter" @click="handlerSubmit" v-loading="loading">{{ $t('sign.desc13') }}</div>
                    <div class="login-tips flex">
                        <div>{{ $t('sign.desc14') }}</div>
                        <p @click="$router.push('/register')">{{ $t('sign.desc15') }}</p>
                    </div>
                    <div class="walletlogin flex" @click="showWallet = true">
                        <img src="../../public/images/new/walletlogin-ico.png" alt="" />
                        {{ $t("sign.desc73") }}
                    </div>
                </div>
                <div v-else class="wallet-login-box">
                    <div class="wallet-title">
                        {{ stepIndex == 1 ? $t("sign.desc73") : $t("sign.desc75") }}
                    </div>
                    <div class="wallet-tip" :class="stepIndex == 1 ? '' : 'invite-tip'">
                        {{ stepIndex == 1 ? $t("sign.desc76") :$t("sign.desc77") }}
                    </div>
                    <div class="wallet-content" v-if="stepIndex == 1">
                        <div class="wallet-btn-card flex" @click="metaMask(1)" v-show="$store.state.showMataMask&&!isMobile">
                        <img src="../../public/images/new/metamask-ico.png" alt="" />
                        MetaMask
                        </div>
                        <div class="wallet-btn-card flex" @click="metaMask(isMobile?1:2)" v-show="$store.state.showMataMask">
                        <img src="../../public/images/new/bitgetwallet-ico.png" alt="" />
                        Bitget Wallet 
                        </div>
                        <div class="wallet-btn-card flex" @click="metaMask(1)" v-show="$store.state.showMataMask&&isMobile">
                        <img src="../../public/images/new/walletlogin-ico.png" alt="" />
                        Wallet Connect
                        </div>
                        <!-- pc端和h5普通浏览器显示条件 -->
                        <div class="wallet-btn-card flex" @click="metaMask(3)" v-show="!isMobile || (isMobile && !$store.state.showMataMask)">
                        <img src="../../public/images/new/walletconnect-ico.png" alt="" />
                        {{
                            connectData.isConnect == true
                            ? dealAddress(connectData.address)
                            : "WalletConnect"
                        }}
                        </div>
                        <div
                        class="wallet-btn-card flex"
                        v-if="connectData.isConnect == true"
                        style="background: #c9fa5b; color: #000000"
                        @click="walletDisConnect"
                        >
                        {{ $t("sign.desc78") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
import bus from "@/utils/bus";
export default {
    inject: ['reload'],
    components: {
        h5header
    },
    data() {
        return {
            check: false,
            account: '', //Musk-01  
            password: '', // Abc123456
            loading: false,
            hvurl: '',
            psdtype: 'password',
            showHv:true,
            showWallet:false,
            
            stepIndex: 1,
            connectModel: {},
            isMobile: false,
            connectData: { address: undefined, chainId: undefined, isConnect: false },
        }
    },

    mounted() {
        if (localStorage.getItem('useract') != null) {
            let data = JSON.parse(localStorage.getItem('useract'));
            this.account = data.account;
            this.password = data.psd;
            this.check = true;
        }
        this.getShowHv(); //检测是否需要展示第三方登录
        let info = this.$store.state.h5Url.find(item => item.tag == 'HC_AUTH')
        this.hvurl = info.url;
        this.isMobile = this.$wallet.isMobile();
        setTimeout(() => {
            this.getAccount();
        }, 1000);
    },
    methods: {

        async getAccount() {
            this.connectData = await this.$wallet.getConnect();
        },
        dealAddress(str) {
            if (str && str.length > 10) {
                return (
                    str.substring(0, 6) +
                    "...." +
                    str.substring(str.length - 4, str.length)
                );
            } else {
                return str;
            }
        },
        handlerEye() {
            this.psdtype == 'password' ? this.psdtype = 'text' : this.psdtype = 'password'
        },
        handlerLink() {
            window.location.href = this.hvurl
        },
        // handlerLink() {
        //      //   this.$router.push('/error')
        // //    return;
        //     window.location.href = this.hvurl
        // },
        handlerClear() {
            this.account = ''
        },
        handlerCheck() {
            this.check ? this.check = false : this.check = true;
        },
        handlerSubmit() {
            if (this.account == '') {
                this.$message.error(this.$t('sign.desc7'));
                return;
            }
            if (this.password == '') {
                this.$message.error(this.$t('sign.desc10'));
                return;
            }
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.$post(this.URL.login.sign, {
                accountType: 2,
                account: this.account,
                code: this.common.mdpassword(this.password)
            }).then(res => {
                if (res.code == 0) {

                    // this.loading = false;
                    localStorage.setItem('token', res.data.token);
                    this.$store.commit('SET_HVUSERSHOW',false);
                    this.$store.commit('SET_ISHVUSER',false);
                    this.$store.commit('SET_TRANSFERSHOW',true);
                    if (this.check) {
                        let jspn = {
                            account: this.account,
                            psd: this.password
                        }
                        localStorage.setItem('useract', JSON.stringify(jspn))
                    } else {
                        localStorage.removeItem('useract')
                    }
                    if (res.data.account == '') {
                        localStorage.setItem('email', this.account)
                        this.$router.push('/bindname')
                        return;
                    }
                    let msg = this.$message.success(this.$t('sign.desc40'))
                    setTimeout(() => {
                        msg.close();
                    }, 1000);
                    this.getinfo()
                } else {
                    this.loading = false;
                    this.$message.error(res.message)
                }
            })
        },
        getinfo() {
            this.$post(this.URL.member.profile, {
            }).then((ress) => {
                if (ress.code == 0) {

                    this.$store.commit('SET_USERDATA', ress.data)
                    this.$store.commit('SET_LOGIN', true)
                    this.$store.commit("SET_USERINFO", ress.data);
                    if(localStorage.getItem('ismobile') == 0){
                        this.$router.push('/home');
                    }else{
                        this.$router.push('/stake');
                    }
                    
                    bus.emit('onLogin')
                    this.reload();
                    // setTimeout(() => {
                    //     location.reload();
                    // }, 2000);
                }
            });
        },
        getShowHv(){
            this.$post(this.URL.login.showHvLogin, {
            }).then((res) => {
                if (res.code == 0) {
                    this.showHv = res.data.isShow;
                }
            });
        },

        // Meta Mask  钱包链接 toLowerCase()
        async metaMask(t) {
            this.showLoading = true;
            let connectresult = await this.$wallet.walletconnect(t);
            this.showLoading = false;
            if (connectresult.agree) {
                if (connectresult.state) {
                    if (t == 2) {
                        if(!this.connectData.isConnect) {
                            this.connectData.address = connectresult.address;
                            this.connectData.chainId = connectresult.chainId;
                            this.connectData.isConnect = true;
                        }
                    }

                    if (
                        this.connectModel.address == undefined ||
                        this.connectModel.chainId == undefined
                    ) {
                        this.connectModel = {
                        connectNumber: t,
                        address: connectresult.address,
                        chainId: connectresult.chainId,
                        };
                    }
                    if (t == 2 && this.connectData.isConnect == true) {
                        address = this.connectData.address;
                        chainId = this.connectData.chainId;
                    }
                    this.prelogin();
                }
            }
        },
        prelogin(){
            this.$post(this.URL.home.getKey, {
                address: this.connectModel.address,
                chainId: this.connectModel.chainId + "",
            }).then(async (res) => {
                if (res.code == 0) {
                    let signResult = await this.$wallet.personalSign(res.data + "", this.connectModel.address);
                    if (signResult.agree) {
                        this.goAuth({
                            address: this.connectModel.address,
                            chainId: this.connectModel.chainId + "",
                            walletSign: signResult.signature
                        });
                    } else {
                        this.$message.error(signResult);
                        setTimeout(() => {
                            location.reload();
                        }, 1000);
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        goAuth(_data) {
            this.$post(this.URL.home.userAuth, _data).then((res) => {
                console.log(res);
                if (res.code == 0) {
                    this.$store.commit("SET_TOKEN", res.data.token);
                    localStorage.setItem("token", res.data.token);
                    this.$post(this.URL.member.info, {}).then((ress) => {
                        if (res.code == 0) {
                            this.$store.commit("SET_USERDATA", ress.data);
                            this.$store.commit("SET_LOGIN", true);
                            this.$store.commit("SET_USERINFO", ress.data);
                            if(localStorage.getItem('ismobile') == 0){
                                this.$router.push('/home');
                            }else{
                                this.$router.push('/stake');
                            }
                            setTimeout(() => {
                                location.reload();
                            }, 2000);
                        }
                    });
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        walletDisConnect(){
            this.$wallet.disconnect();
                this.connectData = {
                    address: undefined,
                    chainId: undefined,
                    isConnect: false,
                };
            setTimeout(() => {
                location.reload();
            }, 1000);
        }
    }
}
</script>

<style lang="less" scoped>
.login {
    padding: 156px 0 117px;
    background: url('../../public/images/new/loginbg.png') center no-repeat;
    background-size: cover;
    background-color: #000000;
    min-height: 875px;

    .login-con {
        width: 1240px;
        margin: 0 auto;
        justify-content: space-between;

        .login-left {
            position: relative;
            flex: 0 0 620px;
            padding-top: 40px;
            color: #FFFFFF;
            min-height: 689px;

            .left-name {
                margin-bottom: 20px;
                font-size: 30px;

            }

            .left-title {
                font-size: 20px;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 736px;
                height: 689px;
            }

        }
        .login-top {
            margin-top: 20px;
        }
        .login-right {
            flex: 0 0 480px;
            padding: 40px;
            height: fit-content;
            background: rgba(24, 24, 24, 0.8);
            border-radius: 12px;
            color: #fff;
            position: relative;

            .back-btn {
                position: absolute;
                top: -25px;
                left: 0px;
                cursor: pointer;
                color: #fff;
                font-family: Bai Jamjuree;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                img {
                    width: 12px;
                    height: 14px;
                    margin-right: 4px;
                }
            }

            .right-name {
                padding-bottom: 4px;
                font-size: 20px;
            }

            .right-title {
                padding: 20px 0 12px;
                font-size: 14px;
                line-height: 14px;
            }

            .right-input {
                position: relative;
                padding: 15px 16px;
                border-radius: 6px;
                border: 1px solid #393939;
                box-sizing: border-box;

                &:hover {
                    border: 1px solid #C9FA5B;
                }

                img {
                    width: 16px;
                    height: 16px;
                    margin-left: 16px;
                    cursor: pointer;
                }

                input {
                    flex: 1;
                    height: 16px;
                    font-size: 14px;
                    color: #fff;

                    &::placeholder {
                        color: #5F5F5F;
                    }
                }
            }

            .right-forgot {
                margin-top: 12px;
                justify-content: space-between;
                line-height: 16px;

                div {
                    font-size: 13px;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                        cursor: pointer;
                    }
                }

                p {
                    font-size: 14px;
                    color: #C9FA5B;
                    cursor: pointer;

                }
            }

            .login-btn {
                margin-top: 32px;
                height: 55px;
                background: url('../../public/images/new/btnbg12.png') center no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                font-size: 18px;
                color: #000000;
            }

            .login-tips {
                margin: 20px 0 32px;
                justify-content: center;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6);

                p {
                    color: #C9FA5B;
                    cursor: pointer;
                    margin-left: 5px;
                }
            }

            .hvlogin {
                height: 44px;
                justify-content: center;
                border-radius: 6px;
                border: 1px solid #393939;
                box-sizing: border-box;
                line-height: 42px;
                cursor: pointer;

                img {
                    width: 24px;
                    height: 24px;
                    margin: 9px 8px 0 0;
                }
            }
            .walletlogin {
                height: 44px;
                justify-content: center;
                border-radius: 6px;
                border: 1px solid #393939;
                box-sizing: border-box;
                line-height: 42px;
                align-items: center;
                justify-content: center;
                position: relative;
                cursor: pointer;

                img {
                position: absolute;
                width: 22px;
                height: 20px;
                left: 25px;
                }
            }
        }

        .wallet-login-box {
      .wallet-title {
        color: #fff;
        font-family: Bai Jamjuree;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .wallet-tip {
        color: #fff;
        font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        margin-top: 27px;
      }
      .invite-tip {
        margin-top: 20px;
        margin-bottom: 12px;
      }
      .wallet-content {
        margin-top: 40px;
        display: flex;
        gap: 24px;
        flex-direction: column;
        
        .wallet-btn-card {
          height: 44px;
          justify-content: center;
          border-radius: 6px;
          border: 1px solid #393939;
          box-sizing: border-box;
          line-height: 42px;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          img {
            position: absolute;
            width: 22px;
            height: 20px;
            left: 25px;
          }
        }
      }
    }
    }
}

@media (max-width:1200px) {
    .login {
        background: #000000;
        padding: 108px 0;
        min-height: inherit;

        .login-con {
            width: 100%;
            justify-content: center;

            .login-left {
                display: none;
            }

            .login-right {}
        }
    }
}

@media (max-width:767px) {
    .login {
        padding: 56px 0;

        .login-con {
            width: calc(100vw - 8px);

            .login-right {
                flex: 0 0 100%;
                width: 100%;
                padding: 20px 16px;

                .right-name {
                    font-size: 16px;
                }

                .right-title {
                    padding: 16px 0;
                }

                .right-input {
                    padding: 12px 15px;
                }

                .login-btn {
                    margin-top: 24px;
                    font-size: 16px;
                }

                .login-tips {
                    margin: 12px 0 24px;
                }
            }
        }
    }
}</style>
